// assets
import { IconLogout } from '@tabler/icons';

// constant
const icons = {
    IconLogout: IconLogout
};

//-----------------------|| Logout MENU ITEMS ||-----------------------//

export const logout = {
    id: 'logout',
    title: 'Logout',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Logout',
            type: 'item',
            url: '/pages/logout',
            icon: icons['IconLogout'],
            breadcrumbs: false
        }
    ]
};
