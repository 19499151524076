// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const order = {
    id: 'dashboard',
    class:'btn-ayyan',
    title: 'Orders',
    type: 'group',
    children: [
        {
            
            id: 'default',
            title: 'Report',
            type: 'item',
            url: '/orders-table',
            // icon: icons['IconDashboard'],
            breadcrumbs: false
        },
    ]
};
