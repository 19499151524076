// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    class:'btn-ayyan',
    title: 'Orders',
    type: 'group',
    children: [
        {
            
            id: 'default',
            title: 'Report',
            type: 'item',
            url: '/orders-table',
            // icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            
            id: 'default',
            title: 'Cancelled',
            type: 'item',
            url: '/cancelled-orders',
            breadcrumbs: false
        },
        {
            
            id: 'default',
            title: 'CSV',
            type: 'item',
            url: '/tempOrder',
            breadcrumbs: false
        }
    ]
};
