// assets
import { IconSubtask , IconDeviceAnalytics} from '@tabler/icons';

// constant
const icons = {
    IconSubtask: IconSubtask,
    IconDeviceAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const assignLead = {
    id: 'assignLead',
    title: 'AssignLead',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'AssignLead',
            type: 'item',
           // url: '/dashboard/default',
            url: '/assign-lead',
            icon: icons['IconSubtask'],
            breadcrumbs: false
        }
    ]
};
