import { dashboard } from "./dashboard";
import { pages } from "./pages";
import { logout } from "./logout";
import { utilities } from "./utilities";
import { other } from "./other";
import { exportTable } from "./exportTable";
import { assignLead } from "./assignLead";
import { callSummaryTable } from "./callSummaryTable";
import { superAdminNav } from "./superAdminNav";
import { payment } from "./payment";
import { order } from "./order";

//-----------------------|| MENU ITEMS ||-----------------------//
let menuItems = null;
let superAdminMenuItems = null;
let paymentAdminMenuItems = null;

menuItems = {
  items: [dashboard, logout],
};

superAdminMenuItems = {
  items: [order, superAdminNav, logout],
};

paymentAdminMenuItems = {
  items: [order, payment, logout],
};

export { menuItems, superAdminMenuItems, paymentAdminMenuItems };
