const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/orders-table',
    //defaultPath: '/dashboard/default',
    defaultPath: '/orders-table',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    background:'btn-ayyan'
};

export default config;
