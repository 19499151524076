import React from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import {menuItems,superAdminMenuItems, paymentAdminMenuItems} from './../../../../menu-items';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {


    let menuItem = null;
    let history = useHistory();
    
    if(!Cookies.get('user_cookie')) //include role id present check as well.
    {
        history.push({
            pathname: '/pages/login'
            })
            return false; //will stop further execution
    }

    if(Cookies.get('role_id') == 5)
    {
        menuItem = menuItems;
    }

    if(Cookies.get('role_id') == 6)
    {
        menuItem = superAdminMenuItems;
    }
    if(Cookies.get('role_id') == 7)
    {
        menuItem = paymentAdminMenuItems;
    }



    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
