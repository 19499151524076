import {IconBox,IconDeviceAnalytics} from '@tabler/icons';


const icons = {
    IconDashboard: IconBox,
    IconDeviceAnalytics
};

export const payment = {
    id: 'payment',
    class:'btn-ayyan',
    title: 'Payment Tab',
    type: 'group',
    children: [
        {
            
            id: 'default',
            title: 'Payment',
            type: 'item',
           // url: '/dashboard/default',
            url: '/payment-table',
            icon: icons['IconBox'],
            breadcrumbs: false
        },
        {
            
            id: 'default',
            title: 'Payout',
            type: 'item',
           // url: '/dashboard/default',
            url: '/payout',
            icon: icons['IconBox'],
            breadcrumbs: false
        },
        {
            
            id: 'default',
            title: 'Report',
            type: 'item',
           // url: '/dashboard/default',
            url: '/payment-transactions',
            icon: icons['IconBox'],
            breadcrumbs: false
        }
    ]
};