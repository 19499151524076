import {IconReportMoney, IconBox,IconDeviceAnalytics} from '@tabler/icons';


const icons = {
    IconDashboard: IconBox,
    IconDeviceAnalytics,
    IconReportMoney: IconReportMoney,
};

export const superAdminNav = {
    id: 'account',
    class:'btn-ayyan',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            
            id: 'default',
            title: 'Payment Details',
            type: 'item',
           // url: '/dashboard/default',
            url: '/paymentDetails',
            icon: icons['IconBox'],
            breadcrumbs: false
        },
        {
            
            id: 'default',
            title: 'Credits',
            type: 'item',
           // url: '/dashboard/default',
            url: '/credit',
            icon: icons['IconReportMoney'],
            breadcrumbs: false
        }
    ]
};