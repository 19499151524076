// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const callSummaryTable = {
    id: 'callSummaryTable',
    title: 'Summary',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Summary',
            type: 'item',
           // url: '/dashboard/default',
            url: '/call-summary',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        }
    ]
};
