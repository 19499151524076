import React, { lazy } from 'react';

import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//lead table
const OrdersTable = Loadable(lazy(() => import('../views/table/OrdersTable')));
const CancelledOrdersTable = Loadable(lazy(() => import('../views/table/CancelledOrdersTable')));
const ExportTable = Loadable(lazy(() => import('../views/table/ExportTable')));
const Users     = Loadable(lazy(() => import('../views/table/Users')));
const EditUser  = Loadable(lazy(() => import('../views/Forms/EditUser')));
const Lead_form  = Loadable(lazy(() => import('../views/Forms/Lead_form')));
const EditLead  = Loadable(lazy(() => import('../views/Forms/EditLead')));
const AssignLead = Loadable(lazy(() => import('../views/Forms/LeadAssign')));
const callSummaryTable = Loadable(lazy(() => import('../views/table/CallSummaryTable')));
const CreateOrder = Loadable(lazy(() => import('../views/table/CreateOrder')));
const EditOrder = Loadable(lazy(() => import('../views/table/EditOrder')));
const EditCancelledOrder = Loadable(lazy(() => import('../views/table/EditCancelledOrder')));
const PaymentDetailsTable = Loadable(lazy(()=> import('../views/table/superAdmin/PaymentDetailsTable')));
const EditPaymentDetails = Loadable(lazy(()=> import('../views/table/superAdmin/EditPaymentDetails')));
const CreditTable = Loadable(lazy(()=> import('../views/table/superAdmin/CreditTable')));
const EditPaymentAmt = Loadable(lazy(()=> import('../views/table/superAdmin/UpdatePaymentAd')));

const EditOrderPaymentAdmin = Loadable(lazy(() => import('../views/table/paymentAdmin/EditOrder')));
 

const TempOrderTable = Loadable(lazy(()=> import('../views/table/tempOrder/TempOrderTable')));
const ViewTempOrderDetails = Loadable(lazy(()=> import('../views/table/tempOrder/ViewTempOrderDetails')));

const PayoutTable = Loadable(lazy(()=> import('../views/table/paymentAdmin/payout/PayoutTable')));
const CreatePayout = Loadable(lazy(()=> import('../views/table/paymentAdmin/payout/CreatePayout')));

const PaymentTransactionsTable = Loadable(lazy(()=> import('../views/table/paymentAdmin/paymentTransactions/PaymentTransactionsTable')));
const PaymentTable = Loadable(lazy(()=> import('../views/table/paymentAdmin/PaymentTable')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        
        <Route
            path={[
                '/dashboard/default',

                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',

                '/sample-page',

                '/orders-table',
                '/cancelled-orders',
                '/export-table',
                '/assign-lead',
                '/users',
                '/edituser',
                '/leadform',
                '/editlead',
                '/call-summary',
                '/create-order',
                '/edit-order',
                '/edit-cancelled-order',
                '/paymentDetails',
                '/edit-paymentDetails',
                '/credit',
                '/update-payment',
                '/edit-order-payment-admin',
                
                '/tempOrder',
                '/view-temp-order-details',

                '/payout',
                '/create-payout',

                '/payment-transactions',

                '/payment-table'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <Route path="/dashboard/default" component={DashboardDefault} />

                    <Route path="/utils/util-typography" component={UtilsTypography} />
                    <Route path="/utils/util-color" component={UtilsColor} />
                    <Route path="/utils/util-shadow" component={UtilsShadow} />
                    <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                    <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                    <Route path="/sample-page" component={SamplePage} />
                    <Route path="/orders-table" component={OrdersTable} />
                    <Route path="/cancelled-orders" component={CancelledOrdersTable} />
                    <Route path="/export-table" component={ExportTable} />

                    <Route path="/assign-lead" component={AssignLead} />
                    <Route path="/users" component={Users}/>
                    <Route path="/edituser" component={EditUser}/>
                    <Route path="/leadform" component={Lead_form}/>
                    <Route path="/editlead" component={EditLead}/>
                    <Route path="/call-summary" component={callSummaryTable} />
                    <Route path="/create-order" component={CreateOrder} />
                    <Route path="/edit-order" component={EditOrder} />
                    <Route path="/edit-cancelled-order" component={EditCancelledOrder} />

                    <Route path="/paymentDetails" component={PaymentDetailsTable} />
                    <Route path="/edit-paymentDetails" component={EditPaymentDetails} />

                    <Route path="/credit" component={CreditTable} />
                    <Route path="/update-payment" component={EditPaymentAmt} />

                    <Route path="/tempOrder" component={TempOrderTable} />
                    <Route path="/view-temp-order-details" component={ViewTempOrderDetails} />

                    <Route path="/payout" component={PayoutTable} />
                    <Route path="/create-payout" component={CreatePayout} />

                    <Route path="/payment-transactions" component={PaymentTransactionsTable} />
                    <Route path="/edit-order-payment-admin" component={EditOrderPaymentAdmin} />

                    <Route path="/payment-table" component={PaymentTable} />
                </Switch>
            </MainLayout>
          
        </Route>
       
    );
};

export default MainRoutes;
