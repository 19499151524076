// assets
import {IconDownload } from '@tabler/icons';

// constant
const icons = {
    IconDownload: IconDownload
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const exportTable = {
    id: 'export',
    title: 'Export',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Export',
            type: 'item',
           // url: '/dashboard/default',
            url: '/export-table',
            icon: icons['IconDownload'],
            breadcrumbs: false
        }
    ]
};
